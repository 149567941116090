html,
body,
.container-fluid,
.nav,
#root {
    height: 100%;
}

.side-navbar {
    width: 180px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background-color: #000000;
    transition: 0.5s;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 180px;
}

#menu-btn {
    background-color: #000000;
    color: #fff;
    margin-left: -62px;
}


.img-upload-btn {
    position: relative;
    overflow: hidden;
    padding-top: 95%;
}

.img-upload-btn input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.img-upload-btn i {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
}

.flex-column img {
    cursor: move;
}

.sortable-chosen {
    opacity: .5;
}

.mdb-dataTable th:nth-child(8) {
    width: 200px;
}

.btn.btn-outline-dark {
    width: 100%;
}